import React from 'react';
import { graphql } from 'gatsby'
import { NavBar, Hero, Footer, SEO, ArticleList /*Post*/ } from '@components'
import { pressPageSelector } from '@data'
import '@styles/press.scss'

const Press = (props) => {
  const { data: prismic = {} } = { ...props }
  const data = pressPageSelector(prismic)
  //const { allPost } = { ...data }
  return (
    <section className={'press'}>
      <SEO title={'Press'} />
      <NavBar data={data} />
      <Hero section={'press'} data={data} />
      <ArticleList data={data} />
      {/*<div className={'press__posts'}>
        {allPost.map((post) => {
          const { slugs = [] } = { ...post };
          return (
            <Post 
              key={slugs[0]}
              post={post}
            />
          );
        })} 
      </div>*/}
      <Footer data={data} />
    </section>
  )
}

export const query = graphql`
  query PressPageQuery($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    prismicPressPage(lang: { eq: $lang }) {
      lang
      data {
        body {
          primary {
            navbar_section_logo {
              url
            }
            navbar_section_logo_mobile {
              url
            }
            navbar_section_cta_label_home {
              text
            }
            navbar_section_cta_link_home {
              text
            }
            navbar_section_cta_label_providers {
              text
            }
            navbar_section_cta_link_providers {
              text
            }
            navbar_section_cta_label_companies {
              text
            }
            navbar_section_cta_link_companies {
              text
            }
            navbar_section_cta_label_demo {
              text
            }
            navbar_section_cta_link_demo {
              url
            }
            navbar_section_cta_label_login {
              text
            }
            navbar_section_cta_link_login {
              url
            }
            navbar_section_cta_label_switcher {
              text
            }
            navbar_section_cta_link_switcher {
              text
            }
            hero_title {
              text
            }
            hero_paragraph {
              text
            }
            hero_image {
              url
            }
            hero_cta_label {
              text
            }
            hero_cta_link {
              url
            }
            footer_section_logo {
              url
            }
            footer_section_cta_label_about {
              text
            }
            footer_section_cta_label_with_us {
              text
            }
            footer_section_cta_label_press {
              text
            }
            footer_section_cta_link_press {
              text
            }
            footer_section_cta_label_contact {
              text
            }
            footer_section_logo_facebook {
              url
            }
            footer_section_cta_link_facebook {
              url
            }
            footer_section_logo_instagram {
              url
            }
            footer_section_cta_link_instagram {
              url
            }
            footer_section_logo_linkedin {
              url
            }
            footer_section_cta_link_linkedin {
              url
            }
            footer_section_copyright {
              text
            }
            footer_section_cta_label_privacy {
              text
            }
            footer_section_cta_label_terms {
              text
            }
          }
          items {
            article_list_owner {
              text
            }
            article_list_cta_label {
              text
            }
            article_list_cta_link {
              url
            }
            article_list_date
          }
        }
      }
    }
  }
`

export default Press;